<template>
  <div v-if="show" class="parent-loader">
    <div class="loading-logo" v-if="!isHideLogo">
      <!-- <img
        src="../../assets/images/logo/logo.png"
        alt="Logo"
        style="width: 30px; margin: 0 auto"
      /> -->
    </div>
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Loader",
  props: ["show", "isHideLogo"]
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.parent-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: $percent_100;
  width: $percent_100;
  z-index: 1200;
  background: $rgb_gray_3;
  .loading-logo {
    position: relative;
  }
  .loading {
    position: relative;
  }
}
</style>
