export default {
  namespaced: true,
  state: {
    route_check_point: []
  },
  getters: {},
  mutations: {
    SET_ROUTE_CHECKPOINT(state, val) {
      state.route_check_point = val;
    }
  },
  actions: {}
};
