import ability from "@/libs/acl/ability";
import router from "@/router";

function checkAbility(requiredAbility = { action: "", subject: "" }) {
  return ability.can(requiredAbility.action, requiredAbility.subject);
}
function parseAndStoreAbilities(abilities, onlyReturn, isReload) {
  let newAbilities = [];
  if (abilities) {
    Object.keys(abilities).forEach((subject) => {
      let actions = abilities[subject];
      actions.forEach((action) => {
        newAbilities.push({ action: action, subject });
      });
    });
  }
  if (!onlyReturn) {
    const oldPermission = localStorage.getItem("USER_PERMISSIONS");
    localStorage.setItem("USER_PERMISSIONS", JSON.stringify(newAbilities));
    updateAbility(newAbilities, true);
    if (
      oldPermission &&
      oldPermission != JSON.stringify(newAbilities) &&
      !isReload
    ) {
      setTimeout(() => {
        location.reload(true);
      }, 50);
    } else if (
      localStorage.getItem("USER_ACCOUNT_ID") &&
      !isReload &&
      localStorage.getItem("OLD_USER_ACCOUNT") &&
      localStorage.getItem("USER_ACCOUNT_ID") !==
        localStorage.getItem("OLD_USER_ACCOUNT")
    ) {
      setTimeout(() => {
        location.reload(true);
      }, 50);
    }
  } else {
    return newAbilities;
  }
}
function updateAbility(newAbilities, shouldStore) {
  if (!newAbilities) {
    const userAbility = JSON.parse(localStorage.getItem("USER_PERMISSIONS"));
    newAbilities = userAbility ? userAbility : null;
  } else if (shouldStore) {
    localStorage.setItem("USER_PERMISSIONS", JSON.stringify(newAbilities));
  }
  ability.update(newAbilities);
  // console.log(ability);
}

export default {
  updateAbility,
  parseAndStoreAbilities,
  checkAbility
};
