export default {
  namespaced: true,
  state: {
    dash_global_filter: {},
    dash_drag_enable: false
  },
  getters: {},
  mutations: {
    SET_DASH_GLOBAL_FILTER(state, val) {
      state.dash_global_filter = val;
    },
    SET_DASH_DRAG_ENABLE(state, val) {
      state.dash_drag_enable = val;
    }
  },
  actions: {}
};
