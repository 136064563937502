import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import constants from "@/utils/constants";
import AuthService from "./auth-service";
import router from "@/router";
import store from "@/store";
// window.num = 0;
export default class APIService {
  constructor() {
    this.cancelTokenSource = axios.CancelToken.source();
  }
  async api(headers, data, options = {}) {
    try {
      // headers.reqId = headers.reqId || window.num++;
      // console.log("REQ/", headers);
      // headers.timezone = new Date().getTimezoneOffset();
      const res = await this.sendRequest(
        headers,
        data || {},
        options,
        process.env.VUE_APP_API
      );
      // console.log("RES/", headers, res.data?.error?.code);

      this.handleCommonResponse(res);
      this.handleAuthorizationError(res);
      this.handleServerErrors(res);
      const { error } = res.data;
      if (
        error &&
        error.code === "EXCEPTION" &&
        !localStorage.getItem("accessToken")
      ) {
        this.handleLogoutAndRedirect();
        throw new Error("response.errorDetails");
      } else if (
        error &&
        error.code &&
        constants.AVOID_LOGOUT_ERROR_CODES.includes(error.code)
      ) {
        if (localStorage.getItem("BEARER_TOKEN")) {
          const authRetryCheck = await this.watchTokenExchangeErrors(
            res,
            headers
          );
          if (authRetryCheck) {
            // console.log(headers);
            return this.api(headers, data, options);
          }
        } else {
          if (window.auth_is_in_logout_mode) {
            error.message = "";
            this.handleLogoutAndRedirect();
          } else {
            window.auth_is_in_logout_mode = true;
            return res.data;
          }
        }
      }

      return res.data;
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log("Request canceled", e.message);
      } else {
        console.log("Error while in auth>>>>", e);
        this.handleServerError();
        // return {
        //   error: {
        //     header: { status: "ERROR" },
        //     message: "Server error, please try after some time"
        //   }
        // };
      }
    }
  }

  async fileApi(data) {
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.VUE_APP_FILE_URL_UPLOAD}`,
        headers: {
          authorization: `bearer ${process.env.VUE_APP_POLY_REALM}`,
          "Content-Type": "multipart/form-data",
          token:
            localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName) ||
            undefined,
          account: localStorage.getItem("USER_ACCOUNT_ID") || undefined,
          version: process.env.VUE_APP_VERSION
        },
        data
      });
      return res.data;
    } catch (e) {
      this.handleServerError();
      return {
        error: {
          header: { status: "ERROR" },
          message: "Server error, please try after some time"
        }
      };
    }
  }
  async fileMessages(data, url) {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.VUE_APP_FILE_URL_CLIENT_CONFIG}${url}`,
        headers: {
          authorization: `bearer ${process.env.VUE_APP_POLY_REALM}`,
          "Content-Type": "multipart/form-data",
          token:
            localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName) ||
            undefined,
          account: localStorage.getItem("USER_ACCOUNT_ID") || undefined,
          version: process.env.VUE_APP_VERSION
        },
        data: {}
      });
      return res.data;
    } catch (e) {
      this.handleServerError();
      return {
        error: {
          header: { status: "ERROR" },
          message: "Server error, please try after some time"
        }
      };
    }
  }
  async sendRequest(headers, data, options, url, contentType) {
    const { show_loader, http_headers, api_version } = options;
    const authHeader = `bearer ${process.env.VUE_APP_POLY_REALM}`;
    const token = !headers.isBearer
      ? localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
      : undefined;

    const requestConfig = {
      method: "post",
      url,
      headers: {
        authorization: authHeader,
        ...http_headers
      },
      data: {
        header: {
          timezone: new Date().getTimezoneOffset(),
          lang: localStorage.getItem("lang") || "en",
          method: headers.method,
          service: headers.service,
          token: token || undefined,
          account: localStorage.getItem("USER_ACCOUNT_ID") || undefined,
          version: api_version || process.env.VUE_APP_VERSION
        },
        data
      },
      timeout: constants.REQUEST_TIME_OUT, // Timeout set to 30 seconds (adjust as needed)
      cancelToken: this.cancelTokenSource.token
    };

    if (contentType) {
      requestConfig.headers["Content-Type"] = contentType;
    }

    try {
      const response = await axios(requestConfig);
      localStorage.removeItem("IS_SERVER_ERROR");
      return response;
    } catch (error) {
      // Handle timeout error specifically
      if (error.code === "ECONNABORTED") {
        error.message = "Request timed out";
      }

      console.log("Request timed out:", error);
      return {
        data: { error: { code: "TIMEOUT", message: error.message } }
      };
      // Handle other errors
    }
  }

  handleCommonResponse(response) {
    const { error } = response.data;

    if (error && error.code) {
      const errorMessage = error.message;
      const correlationId =
        response.data.header && response.data.header.correlationId;
      error.message = `${errorMessage}${
        correlationId ? ` Correlation ID: ${correlationId}` : ""
      }`;
    }
  }

  handleAuthorizationError(response) {
    const { error } = response.data;

    if (
      error &&
      error.code &&
      constants.UNAUTHORIZED_ERROR_CODES.includes(error.code)
    ) {
      // router.push({ name: "unauthorized" });
      store.commit("authorized/SET_AUTH_PERMISSION", false);
    }
  }

  async handleServerErrors(response) {
    let { error } = response.data;

    if (
      error &&
      error.code &&
      constants.LOGOUT_ERROR_CODES.includes(error.code)
    ) {
      this.handleLogoutAndRedirect(error.code);
    }

    if (
      error &&
      error.code &&
      constants.INVITE_ERROR_CODES.includes(error.code)
    ) {
      if (localStorage.getItem("inviteData")) {
        localStorage.removeItem("inviteData");
      }
    }
  }
  async awaitAuth() {
    while (window.auth_is_in_progress) {
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 500);
      });
    }
  }
  async watchTokenExchangeErrors(response, headers) {
    const { error } = response.data;

    if (window.auth_is_in_progress) {
      await this.awaitAuth();
      return true;
    }
    window.auth_is_in_progress = true;

    const reqObj = { bearer_token: localStorage.getItem("BEARER_TOKEN") };
    if (localStorage.getItem("notificationToken")) {
      reqObj.notification_token = localStorage.getItem("notificationToken");
    }
    const res = await this.api(
      {
        service: "auth-service",
        method: "loginWithBearer",
        // reqId: headers.reqId,
        isBearer: true
      },
      reqObj
    );
    if (res.data && res.data.user_token) {
      localStorage.setItem(
        useJwt.jwtConfig.storageTokenKeyName,
        res.data.user_token
      );

      window.auth_is_in_progress = undefined;
      return true;
    } else if (
      res.error &&
      res.error.code === "BEARER_TOKEN_INVALID_OR_EXPIRED"
    ) {
      localStorage.removeItem("BEARER_TOKEN");
      window.auth_is_in_logout_mode = true;

      this.handleLogoutAndRedirect();
      window.auth_is_in_progress = undefined;
    } else {
      window.auth_is_in_progress = undefined;
    }

    return false;
  }

  handleLogoutAndRedirect(errorCode) {
    this.cancelTokenSource.cancel("Operation canceled due to logout.");
    this.cancelTokenSource = axios.CancelToken.source(); // Reset the cancel token source
    const authService = new AuthService();
    authService.logout({ session_id: localStorage.getItem("sessionId") });

    setTimeout(() => {
      window.auth_is_in_logout_mode = undefined;
      router.push({ name: "login" }).catch(() => {});
    }, 500);
  }

  handleServerError() {
    localStorage.setItem("IS_SERVER_ERROR", "true");
  }
}
