<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <div id="landscape-message" style="display: none">
      <p>
        This website is optimized for portrait mode. Please rotate your device.
      </p>
    </div>
    <component :is="layout" v-if="showLayout"> <router-view /> </component>
    <!-- <Loader :show="show" /> -->
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Loader from "@/layouts/components/Loader.vue";
import { useWindowSize, useCssVar } from "@vueuse/core";

import store from "@/store";
import firebaseApp from "./utils/firebase";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { v4 as uuidv4 } from "uuid";
import acl from "./utils/acl";
import AccountService from "./libs/api/account-service";
import PushService from "./libs/api/push-service";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    Loader
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      const savedClass = localStorage.getItem("savedClass");
      document.body.classList.add(savedClass);
      const classNameSkin = localStorage.getItem("vuexy-skin");
      if (classNameSkin == "dark") {
        document.body.classList.add("dark-layout");
      }
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    }
  },
  async mounted() {
    if (localStorage.getItem("accessToken")) {
      await this.fetchNotificationToken();
    }
    const me = this;

    this.intervalId = setInterval(function () {
      me.getMessages();
    }, 10);
  },
  watch: {
    async $route(to, from) {
      if (localStorage.getItem("USER_ACCOUNT_ID")) {
        const router = this.$router.currentRoute;
        if (this.$router.name !== "forgot-password-II") {
          //   await this.getUpdateAbilities();
        }
      }
    },
    show(newVal) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    getFilteredLists(filter, response) {
      response.data = Object.keys(response.data)
        .filter((key) => !filter.includes(key))
        .reduce((obj, key) => {
          obj[key] = response.data[key];
          return obj;
        }, {});
    },
    getMessages() {
      const localMessages = this.$i18n._getMessages();
      if (
        localMessages &&
        Object.keys(localMessages) &&
        Object.keys(localMessages).length
      ) {
        setTimeout(() => {
          this.show = false;
        }, 1000);

        setTimeout(() => {
          this.showLayout = true;
        }, 200);
      }
    },
    async callSelectAccount() {
      let response = await new AccountService().selectAccount({
        account_id: localStorage.getItem("USER_ACCOUNT_ID")
      });
      if (response && response.data) {
        if (localStorage.getItem("USER_SUB_ACCOUNTS_RESTRICTED") == "true") {
          if (
            ["DEALER", "CONSUMER"].includes(
              localStorage.getItem("USER_ACCOUNT_TYPE")
            )
          ) {
            this.getFilteredLists(["account", "account_devices"], response); //pass in array which we want to exclude
          }
        } else if (localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER") {
          this.getFilteredLists(["devices"], response);
        }

        return acl.parseAndStoreAbilities(response.data, false);
      }
    },
    async getUpdateAbilities() {
      const abilities = await this.callSelectAccount();
      const isServerError = localStorage.getItem("IS_SERVER_ERROR");
      if (
        JSON.stringify(abilities) !==
          localStorage.getItem("USER_PERMISSIONS") &&
        !isServerError === "true"
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("role.RoleUpdatedTitle"),
            icon: "InfoIcon",
            text: this.$t("role.RoleUpdateMessage"),
            variant: "error"
          }
        });
        acl.updateAbility(abilities, true);
        // setTimeout(() => {
        //   // this.$router.go();
        // }, 1000);
      }
    },
    async fetchNotificationToken() {
      const messaging = getMessaging(firebaseApp);
      onMessage(messaging, (payload) => {
        // console.log("Message received. ", payload);
        setTimeout(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: payload.notification.title,
              text: payload.notification.body,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }, 2000);
      });
      // Register the service worker
      navigator.serviceWorker
        .register("/app/firebase-messaging-sw.js")
        .then((serviceWorkerRegistration) => {
          // Once the service worker is registered, call getToken with the registration
          // console.log(serviceWorkerRegistration);
          return getToken(messaging, {
            vapidKey:
              "BG2plIQHsAObZUznnNLjC5nYXFDmC6inHatRvnbjccuagsPpcwkKibJT2adzWiBdAlgb4_cCzfmRShivYzVe4aY",
            serviceWorkerRegistration
          });
        })
        .then(async (currentToken) => {
          if (currentToken) {
            localStorage.setItem("notificationToken", currentToken);
            await new PushService().saveToken({
              token: currentToken,
              status: true
            });
          }
        })
        .catch(async (err) => {
          console.log("error while getting notification token: ", err.message);
          localStorage.removeItem("notificationToken");
          await new PushService().saveToken({
            status: false
          });
        });
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark"
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade"
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses
    };
  },
  data() {
    return {
      show: true,
      intervalId: "",
      showLayout: false
    };
  }
};
</script>
