import constants from "@/utils/constants";

export default [
  {
    title: "SideMenu.Dashboard",

    icon: "DashHomeIcon",

    route: "dashboard",
    icon: "BarChart2Icon",
    resource: "widgets",
    action: constants.LABELS.DASHBOARD
  },

  {
    title: "SideMenu.Users",
    icon: "UsersIcon",
    route: "user-list",
    action: constants.LABELS.MENU,
    resource: "user"
  },
  {
    title: "SideMenu.Account",
    route: {
      name: "accounts"
    },
    icon: "ClipboardIcon",
    children: [
      {
        title: "SideMenu.Dealers",
        route: "dealer",
        action: constants.LABELS.DEALER_MENU,
        resource: "account"
      },
      {
        title: "SideMenu.Consumers",
        route: "consumer",
        action: constants.LABELS.CONSUMER_MENU,
        resource: "account_devices"
      }
    ]
  },
  {
    title: "SideMenu.Devices",
    route: "devices-list",
    icon: "CpuIcon",
    action: constants.LABELS.MENU,
    resource: "devices"
  },
  {
    title: "SideMenu.Units",
    // route: "unit-map",
    icon: "ServerIcon",
    action: constants.LABELS.MENU,
    resource: "units",
    children: [
      {
        title: "SideMenu.ListView",
        route: "unit-list",
        icon: "ListIcon",
        action: constants.LABELS.MENU,
        resource: "units",
        size: "17"
      },
      {
        title: "SideMenu.MapView",
        route: {
          name: "unit-map"
        },
        icon: "MapPinIcon",
        resource: "units",
        action: constants.LABELS.MENU,
        size: "17"
      }
      // {
      //   title: "Map PlayBack",
      //   route: {
      //     name: "unit-map-playback"
      //   },
      //   icon: "MapPinIcon",

      //   size: "17"
      // }
    ]
  },
  {
    title: "SideMenu.Repeaters",
    route: "repeaters",
    icon: "repeater_icon",
    resource: "repeaters",
    action: constants.LABELS.MENU
  },
  {
    title: "SideMenu.Maintenance",
    route: "maintenance-reminder-list",
    icon: "MaintenanceIcon",
    action: constants.LABELS.MENU,
    resource: "service_reminders"
  },
  {
    title: "SideMenu.Migrate",
    route: "migrate-data",
    icon: "RepeatIcon",
    resource: "migrations",
    action: constants.LABELS.MENU
  },
  {
    title: "SideMenu.ReferenceData",
    icon: "UsersIcon",
    route: { name: "reference-data" },
    children: [
      {
        title: "SideMenu.Geofence",
        route: "geofences-list",
        icon: "geo_icon",
        resource: "geofences",
        action: constants.LABELS.MENU
      },
      {
        title: "SideMenu.Routes",
        route: "routes-list",
        icon: "route_icon",
        resource: "routes",
        action: constants.LABELS.MENU
      },
      {
        title: "SideMenu.Alerts",
        route: "alert-list",
        action: constants.LABELS.MENU,
        icon: "alert_icon",
        resource: "alert"
      },

      {
        title: "SideMenu.Groups",
        route: "group-list",
        icon: "group_icon",
        resource: "label",
        action: constants.LABELS.MENU
      },
      {
        title: "SideMenu.Operators",
        route: "operator-list",
        icon: "operator_icon",
        resource: "operator",
        action: constants.LABELS.MENU
      }
    ]
  },
  {
    title: "SideMenu.Notifications",
    route: "notification-list",
    icon: "BellIcon",
    action: constants.LABELS.MENU,
    resource: "notification"
  },
  {
    title: "SideMenu.Reports",
    // route: { name: "report-list" },
    // action: constants.LABELS.MENU,
    icon: "FileTextIcon",
    // resource: "report",

    children: [
      {
        title: "SideMenu.ReportsList",
        route: { name: "report-list" },
        resource: "report",
        action: constants.LABELS.MENU,
        icon: "ReportIcon"
      },
      {
        title: "SideMenu.ReportCreate",
        route: { name: "select-report" },
        icon: "ReportCreateIcon",
        resource: "report",
        action: constants.LABELS.ADD
      }
    ]
  }
];
