import AuthService from "@/libs/api/auth-service";
const authService = new AuthService();

export async function getProfile(ctx) {
  const res = await authService.getAccountDetails();
  ctx.commit("setAccount", res.data);
}
export async function getProfileDetails(ctx) {
  const res = await authService.getUserProfile({});
  ctx.commit("setUserAccount", res.data);
}
