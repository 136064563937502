export default {
  namespaced: true,
  state: {
    fuelSensorUnit: true
  },
  getters: {},
  mutations: {
    SET_FUEL_SENSOR_UNIT(state, val) {
      state.fuelSensorUnit = val;
    }
  },
  actions: {}
};
