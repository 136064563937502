import APIService from "./api";
/** This class will include all services defined in auth-service */
export default class PushService extends APIService {
  service = "push-service";
  async saveToken(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "saveToken" },
      data,
      options
    );
  }
}
