export default {
  namespaced: true,
  state: {
    is_loading: false,
    is_popup_show: true, // popup up down for mobile map view screen,
    is_breadcrumb_loading: false,
    is_live_unit: false,
    is_event_popup_show: false
  },
  getters: {},
  mutations: {
    SET_IS_LOADING(state, val) {
      state.is_loading = val;
    },
    SET_IS_POPUP_SHOW(state, val) {
      state.is_popup_show = val;
    },
    SET_IS_BREADCRUMB_LOADING(state, val) {
      state.is_breadcrumb_loading = val;
    },
    SET_IS_LIVE_UNIT(state, val) {
      state.is_live_unit = val;
    },
    SET_EVENT_POPUP_SHOW(state, val) {
      state.is_event_popup_show = val;
    }
  },
  actions: {}
};
