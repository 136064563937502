export function setProtocolList(state, payload) {
  state.parameters = payload;
}
export function setIcons(state, payload) {
  state.icons = payload;
}

export function videoStreamData(state, payload) {
  state.streamUrlData = payload;
}
