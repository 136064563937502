export default {
  namespaced: true,
  state: {
    auth_permission: true,
    last_route: "",
    is_login: false
  },
  getters: {},
  mutations: {
    SET_AUTH_PERMISSION(state, val) {
      state.auth_permission = val;
    },
    SET_LAST_ROUTE(state, val) {
      state.last_route = val;
    },
    SET_IS_LOGIN(state, val) {
      state.is_login = val;
    }
  },
  actions: {}
};
