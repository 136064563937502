let timezone = "UTC";
try {
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
} catch (err) {
  console.error(
    "Error while getting default timezone from browser, using UTC as default timezone"
  );
}
export default {
  TZStrings: [
    {
      label: "(GMT-00:00) UTC",
      value: "UTC"
    },
    {
      label: "(GMT-12:00) International Date Line West",
      value: "Etc/GMT+12"
    },
    {
      label: "(GMT-11:00) Midway Island, Samoa",
      value: "Pacific/Midway"
    },
    {
      label: "(GMT-10:00) Hawaii",
      value: "Pacific/Honolulu"
    },
    {
      label: "(GMT-09:00) Alaska",
      value: "US/Alaska"
    },
    {
      label: "(GMT-08:00) Pacific Time (US & Canada)",
      value: "America/Los_Angeles"
    },
    {
      label: "(GMT-08:00) Tijuana, Baja California",
      value: "America/Tijuana"
    },
    {
      label: "(GMT-07:00) Arizona",
      value: "US/Arizona"
    },
    {
      label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
      value: "America/Chihuahua"
    },
    {
      label: "(GMT-07:00) Mountain Time (US & Canada)",
      value: "US/Mountain"
    },
    {
      label: "(GMT-06:00) Central America",
      value: "America/Managua"
    },
    {
      label: "(GMT-06:00) Central Time (US & Canada)",
      value: "US/Central"
    },
    {
      label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
      value: "America/Mexico_City"
    },
    {
      label: "(GMT-06:00) Saskatchewan",
      value: "Canada/Saskatchewan"
    },
    {
      label: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
      value: "America/Bogota"
    },
    {
      label: "(GMT-05:00) Eastern Time (US & Canada)",
      value: "US/Eastern"
    },
    {
      label: "(GMT-05:00) Indiana (East)",
      value: "US/East-Indiana"
    },
    {
      label: "(GMT-04:00) Atlantic Time (Canada)",
      value: "Canada/Atlantic"
    },
    {
      label: "(GMT-04:00) Caracas, La Paz",
      value: "America/Caracas"
    },
    {
      label: "(GMT-04:00) Manaus",
      value: "America/Manaus"
    },
    {
      label: "(GMT-04:00) Santiago",
      value: "America/Santiago"
    },
    {
      label: "(GMT-03:30) Newfoundland",
      value: "Canada/Newfoundland"
    },
    {
      label: "(GMT-03:00) Brasilia",
      value: "America/Sao_Paulo"
    },
    {
      label: "(GMT-03:00) Buenos Aires, Georgetown",
      value: "America/Argentina/Buenos_Aires"
    },
    {
      label: "(GMT-03:00) Greenland",
      value: "America/Godthab"
    },
    {
      label: "(GMT-03:00) Montevideo",
      value: "America/Montevideo"
    },
    {
      label: "(GMT-02:00) Mid-Atlantic",
      value: "America/Noronha"
    },
    {
      label: "(GMT-01:00) Cape Verde Is.",
      value: "Atlantic/Cape_Verde"
    },
    {
      label: "(GMT-01:00) Azores",
      value: "Atlantic/Azores"
    },
    {
      label: "(GMT+00:00) Casablanca, Monrovia, Reykjavik",
      value: "Africa/Casablanca"
    },
    {
      label:
        "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
      value: "Etc/Greenwich"
    },
    {
      label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
      value: "Europe/Amsterdam"
    },
    {
      label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
      value: "Europe/Belgrade"
    },
    {
      label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
      value: "Europe/Brussels"
    },
    {
      label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
      value: "Europe/Sarajevo"
    },
    {
      label: "(GMT+01:00) West Central Africa",
      value: "Africa/Lagos"
    },
    {
      label: "(GMT+02:00) Amman",
      value: "Asia/Amman"
    },
    {
      label: "(GMT+02:00) Athens, Bucharest, Istanbul",
      value: "Europe/Athens"
    },
    {
      label: "(GMT+02:00) Beirut",
      value: "Asia/Beirut"
    },
    {
      label: "(GMT+02:00) Cairo",
      value: "Africa/Cairo"
    },
    {
      label: "(GMT+02:00) Harare, Pretoria",
      value: "Africa/Harare"
    },
    {
      label: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
      value: "Europe/Helsinki"
    },
    {
      label: "(GMT+02:00) Jerusalem",
      value: "Asia/Jerusalem"
    },
    {
      label: "(GMT+02:00) Minsk",
      value: "Europe/Minsk"
    },
    {
      label: "(GMT+02:00) Windhoek",
      value: "Africa/Windhoek"
    },
    {
      label: "(GMT+03:00) Kuwait, Riyadh, Baghdad",
      value: "Asia/Kuwait"
    },
    {
      label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
      value: "Europe/Moscow"
    },
    {
      label: "(GMT+03:00) Nairobi",
      value: "Africa/Nairobi"
    },
    {
      label: "(GMT+03:00) Tbilisi",
      value: "Asia/Tbilisi"
    },
    {
      label: "(GMT+03:30) Tehran",
      value: "Asia/Tehran"
    },
    {
      label: "(GMT+04:00) Abu Dhabi, Muscat",
      value: "Asia/Muscat"
    },
    {
      label: "(GMT+04:00) Baku",
      value: "Asia/Baku"
    },
    {
      label: "(GMT+04:00) Yerevan",
      value: "Asia/Yerevan"
    },
    {
      label: "(GMT+04:30) Kabul",
      value: "Asia/Kabul"
    },
    {
      label: "(GMT+05:00) Yekaterinburg",
      value: "Asia/Yekaterinburg"
    },
    {
      label: "(GMT+05:00) Islamabad, Karachi, Tashkent",
      value: "Asia/Karachi"
    },
    {
      label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
      value: "Asia/Calcutta"
    },
    {
      label: "(GMT+05:45) Kathmandu",
      value: "Asia/Katmandu"
    },
    {
      label: "(GMT+06:00) Almaty, Novosibirsk",
      value: "Asia/Almaty"
    },
    {
      label: "(GMT+06:00) Astana, Dhaka",
      value: "Asia/Dhaka"
    },
    {
      label: "(GMT+06:30) Yangon (Rangoon)",
      value: "Asia/Rangoon"
    },
    {
      label: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
      value: "Asia/Bangkok"
    },
    {
      label: "(GMT+07:00) Krasnoyarsk",
      value: "Asia/Krasnoyarsk"
    },
    {
      label: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
      value: "Asia/Hong_Kong"
    },
    {
      label: "(GMT+08:00) Kuala Lumpur, Singapore",
      value: "Asia/Kuala_Lumpur"
    },
    {
      label: "(GMT+08:00) Irkutsk, Ulaan Bataar",
      value: "Asia/Irkutsk"
    },
    {
      label: "(GMT+08:00) Perth",
      value: "Australia/Perth"
    },
    {
      label: "(GMT+08:00) Taipei",
      value: "Asia/Taipei"
    },
    {
      label: "(GMT+09:00) Osaka, Sapporo, Tokyo",
      value: "Asia/Tokyo"
    },
    {
      label: "(GMT+09:00) Seoul",
      value: "Asia/Seoul"
    },
    {
      label: "(GMT+09:00) Yakutsk",
      value: "Asia/Yakutsk"
    },
    {
      label: "(GMT+09:30) Adelaide",
      value: "Australia/Adelaide"
    },
    {
      label: "(GMT+09:30) Darwin",
      value: "Australia/Darwin"
    },
    {
      label: "(GMT+10:00) Brisbane",
      value: "Australia/Brisbane"
    },
    {
      label: "(GMT+10:00) Canberra, Melbourne, Sydney",
      value: "Australia/Canberra"
    },
    {
      label: "(GMT+10:00) Hobart",
      value: "Australia/Hobart"
    },
    {
      label: "(GMT+10:00) Guam, Port Moresby",
      value: "Pacific/Guam"
    },
    {
      label: "(GMT+10:00) Vladivostok",
      value: "Asia/Vladivostok"
    },
    {
      label: "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
      value: "Asia/Magadan"
    },
    {
      label: "(GMT+12:00) Auckland, Wellington",
      value: "Pacific/Auckland"
    },
    {
      label: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
      value: "Pacific/Fiji"
    },
    {
      label: "(GMT+13:00) Nuku'alofa",
      value: "Pacific/Tongatapu"
    }
  ],
  UNAUTHORIZED_ERROR_CODES: ["POLY_ERR_ACCESSRIGHT"],
  LOGOUT_ERROR_CODES: [
    "POLY_ERR_LOGIN_INACTIVE",
    "POLY_ERR_LOGIN_BLOCKED",
    "POLY_ERR_METHOD_MESSAGE",
    "POLY_ERR_ACCOUNT_MISSING",
    "POLY_ERR_ACCESSDINED_MESSAGE",
    "POLY_ERR_SERVICE_MESSAGE",
    "POLY_ERR_ACCOUNT_ACCESS_ERROR",
    "POLY_ERR_ACCOUNT_MISSING_MESSAGE",
    "POLY_ERR_ACCOUNT_INACTIVE"
  ],
  AVOID_LOGOUT_ERROR_CODES: ["POLY_ERR_ACCESSTOKEN"],
  LOGOUT_ERROR_CODE_TIMEOUT: ["POLY_ERR_ACCESSTOKEN_MESSAGE"],

  INVITE_ERROR_CODES: [
    "POLY_ERR_INVITE_ALREADY_ACCEPTED_ERROR",
    "POLY_ERR_INVALID_INVITE_ERROR",
    "POLY_ERR_INVITE_ALREADY_DECLINE_ERROR",
    "POLY_ERR_EXPIRED_INVITE_ERROR",
    "POLY_ERR_USER_MISMATCH_ERROR"
  ],
  MAX_PHONE_NO: 15,
  MAX_DEVICE_VERSION: 50,
  MIN_DEVICE_VERSION: 1,
  MAX_SIM_NUMBER: 15,
  MIN_SIM_NUMBER: 6,
  MAX_SIM_OPERATOR: 20,
  MIN_SIM_OPERATOR: 2,
  MAX_UNIQUE_IDENTIFIER: 16,
  MIN_UNIQUE_IDENTIFIER: 2,
  MIN_LAST_NAME: 2,
  MAX_LAST_NAME: 50,
  MIN_FIRST_NAME: 2,
  MAX_FIRST_NAME: 50,
  MIN_DEVICE_NAME: 3,
  MAX_DEVICE_NAME: 50,
  MAX_PHONE: 40,
  MIN_PHONE: 2,
  MIN_REPORT_NAME: 3,
  MAX_REPORT_NAME: 20,
  PHONE_PATTERN: /^[s()+-]*([0-9][s()+-]*){6,15}$/,
  LABELS: {
    READ: "r",
    ADD: "a",
    UPDATE: "u",
    DELETE: "d",
    MENU: "m",
    DEALER_MENU: "dm",
    CONSUMER_MENU: "cm",
    DASHBOARD: "dash",
    CONSUMER_DASH: "c_dash",
    DEALER_DASH: "d_dash",
    DEALER_DASH: "d_dash",
    DASHBOARD: "d_dash"
  },
  MIN_LAST_NAME: 2,
  MAX_LAST_NAME: 50,
  MIN_FIRST_NAME: 2,
  MAX_FIRST_NAME: 50,
  MIN_ACC_NAME: 3,
  MAX_ACC_NAME: 50,
  MIN_ACC_ADDRESS: 5,
  MAX_ACC_ADDRESS: 80,
  MIN_ACC_REGION: 3,
  MAX_ACC_REGION: 30,
  MAX_ACC_REGISTRATION_NUM: 30,
  MIN_UNIT_NAME: 3,
  MAX_UNIT_NAME: 50,
  MIN_ROLE_NAME: 3,
  MAX_ROLE_NAME: 20,
  ADDRESS_PATTERN: /^.+$/u,
  REGISTRATION_NUMBER_PATTERN: /^\S*$/,
  MIN_OPERATOR_CODE: 3,
  MAX_OPERATOR_CODE: 20,
  CONSUMER_ROUTE_META_BREADCRUMB: [
    {
      text: "Breadcrumb_Accounts",
      to: {
        name: "accounts"
      }
    },
    {
      text: "Breadcrumb_Consumers",
      to: {
        name: "consumer"
      },
      active: true
    }
  ],
  DEALER_ROUTE_META_BREADCRUMB: [
    {
      text: "Breadcrumb_Accounts",
      to: {
        name: "accounts"
      }
    },
    {
      text: "Breadcrumb_Dealers",
      active: true,
      to: {
        name: "dealer"
      }
    }
  ],
  UNIT_TRACKING_ROUTE_META_BREADCRUMB: [
    {
      text: "Breadcrumb_Units",
      to: `/units/map`,
      active: false
    }
  ],
  USER_ROUTE_META_BREADCRUMB: [
    {
      text: "Breadcrumb_Users",
      to: {
        name: "user-list",
        query: {
          tab: "users"
        }
      }
    }
  ],
  ROLE_CREATE_ROUTE_META_BREADCRUMB: [
    {
      text: "Breadcrumb_Users",
      to: {
        name: "user-list",
        query: {
          tab: "users"
        }
      }
    },
    {
      text: "Breadcrumb_Roles",
      to: {
        name: "user-list",
        query: {
          tab: "roles"
        }
      }
    }
  ],
  DEVICES_ROUTE_META_BREADCRUMB: [
    {
      text: "Breadcrumb_Devices",
      to: {
        name: "devices-list"
      }
    }
  ],
  REPORTS_ROUTE_META_BREADCRUMB: [
    {
      text: "Breadcrumb_Reports",
      to: {
        name: "report-list"
      }
    }
  ],
  ALERTS_ROUTE_META_BREADCRUMB: [
    {
      text: "Breadcrumb_Reference Data",
      to: {
        name: "reference-data"
      }
    },
    {
      text: "Breadcrumb_Alerts",
      to: {
        name: "alert-list"
      }
    }
  ],
  UNIT_ROUTE_META_BREADCRUMB: [
    {
      text: "Breadcrumb_Units",
      to: {
        name: "unit-list"
      }
    }
  ],
  GROUP_ROUTE_META_BREADCRUMB: [
    {
      text: "Breadcrumb_Reference Data",
      to: {
        name: "reference-data"
      }
    },
    {
      text: "Breadcrumb_Groups",
      to: {
        name: "group-list"
      }
    }
  ],
  OPERATOR_ROUTE_META_BREADCRUMB: [
    {
      text: "Breadcrumb_Reference Data",
      to: {
        name: "reference-data"
      }
    },
    {
      text: "Breadcrumb_Operators",
      to: {
        name: "operator-list"
      }
    }
  ],
  NOTIFICATION_ROUTE_META_BREADCRUMB: [
    {
      text: "Breadcrumb_Notifications",
      to: {
        name: "notification-list"
      }
    }
  ],
  USER_INVITATION_ROUTE_META_BREADCRUMB: [
    {
      text: "Breadcrumb_Profile",
      to: {
        name: "user-invitations"
      }
    },
    {
      text: "Breadcrumb_Invitations",
      to: {
        name: "user-invitations",
        query: {
          tab: "pending"
        }
      }
    }
  ],
  MAINTENANCE_REMINDERS_BREADCRUMB: [
    {
      text: "Breadcrumb_MaintenanceReminders",
      to: { name: "maintenance-reminder-list" }
    }
  ],
  PERMISSIONS_MODEL: {
    USERS: "user",
    ACCOUNT: "account",
    CONSUMER_ACCOUNT: "account_devices",
    USER_ACCOUNT_INVITATION: "user_accounts_invitations",
    UNITS: "units",
    ROLE: "role",
    MIGRATION: "migrations",
    DEVICES: "devices",
    USER_ACCOUNT: "users_accounts",
    USER_ACCOUNT_CONSUMER: "vw_accounts",
    VW_USER_ACCOUNTS: "vw_user_accounts",
    VW_CHILDREN_ACCOUNTS: "vw_children_accounts",
    ALERTS: "alert",
    LABELS: "label",
    OPERATORS: "operator",
    NOTIFICATION: "notification",
    REPORTS: "report",
    REPEATERS: "repeaters",
    ACCOUNT_UNITS: "account_units",
    WIDGETS: "widgets",
    SERVICE_REMINDERS: "service_reminders",
    GEOFENCES: "geofences",
    ROUTES: "routes"
  },
  PERMISSIONS_ACTION: {
    READ: "r",
    ADD: "a",
    UPDATE: "u",
    DELETE: "d",
    ACCOUNT_READ: "ar",
    ACCOUNT_ADD: "aa",
    ACCOUNT_UPDATE: "au",
    MENU: "m",
    DEALER_MENU: "dm",
    CONSUMER_MENU: "cm",
    SHOW_TRIPS: "st",
    SHOW_SERVICES: "ss",
    SHOW_EVENTS: "se",
    SHOW_VIDEOS: "sv",
    SHOW_ACTIVITY: "sa",
    SHOW_HISTORY: "sh",
    DASHBOARD: "dash",
    CARD_ORDERING: "cv",
    UPDATE_CHILD_ACCOUNT: "au",
    UPDATE_CONSUMER: "cu",
    UPDATE_CONSUMER_CHILD_ACCOUNT: "cau",
    CONSUMER_ACCOUNT_ADD: "caa",
    CONSUMER_ADD: "ca",
    CONSUMER_ACCOUNT_READ: "car",
    CONSUMER_READ: "cr",
    CONSUMER_DASH_SUB_ACC: "dash_a_r",
    SUB_ACCOUNT_REPORT_GENERATE: "rsag",
    NOTIFICATION_PREFERENCES_SAVE: "ns",
    EXECUTE_COMMAND: "ec",
    EXECUTE_COMMAND_LOG_HISTORY: "ch",
    GEOFENCES_SUB_ACCOUNT: "gsa",
    ROUTES_SUB_ACCOUNT: "rsa",
    LABELS_SUB_ACCOUNT: "lsa",
    REPEATER_SUB_ACCOUNT: "rsa",
    ALERTS_SUB_ACCOUNT: "asa"
  },
  TripDetector: [
    {
      label: "Auto",
      value: "auto"
    },
    {
      label: "Ignition",
      value: "ignition"
    },
    {
      label: "GPS Coordinates",
      value: "gps_coordinates"
    },
    {
      label: "Relative Odometer",
      value: "relative_odometer"
    },
    {
      label: "Odometer",
      value: "odometer"
    },
    {
      label: "Off",
      value: "off"
    }
  ],
  GROUP_ICONS: {
    UNITS: "UNITS",
    ALERTS: "ALERTS",
    DEVICES: "DEVICES",
    NOTIFICATIONS: "NOTIFICATIONS",
    SCHEDULES: "SCHEDULES",
    WEBHOOKS: "WEBHOOKS",
    REPEATERS: "REPEATERS",
    OPERATORS: "OPERATORS",
    REPORTS: "REPORTS",
    MAINTENANCE: "MAINTENANCE",
    GEOFENCES: "GEOFENCE",
    ROUTES: "ROUTE"
  },
  TRIP_DETECTOR_DEFAULT_VALUE: {
    m_source: "auto",
    min_m_speed: 3,
    min_park_time: 300,
    gps_correct: true,
    max_dist_in_msgs: 1000,
    max_intrl_in_msgs: 300,
    min_trip_time: 60,
    min_trip_dist: 200,
    min_satellites: 20
  },
  FUEL_THEFT_DEFAULT_VALUE: {
    max_threshold: 100,
    min_threshold: 0,
    enable: true
  },
  FUEL_THEFT_DEFAULT_VALUE_NEW: {
    fuel_theft: {
      enable: true,
      km_per_liter: 5,
      hour_per_liter: 3,
      min_theft: "",
      observe_time: ""
    },
    fuel_refill: {
      enable: true,
      min_refill: "",
      observe_time: "",
      min_change: 2
    }
  },
  SUPPORTED_LANGUAGES: [
    {
      locale: "en",
      img: require("@/assets/images/flags/en.png"),
      name: "English"
    },
    {
      locale: "ru",
      img: require("@/assets/images/flags/ru.png"),
      name: "Russian"
    }
  ],
  SUPPORTED_RTL_LANGUAGES: [],
  THEMES: [
    {
      value: "default-layout",
      label: "Purple",
      code: "$primary",
      color: "#7367f0"
    },
    {
      value: "blue-layout",
      label: "Blue",
      code: "$theme-blue",
      color: "#0d6efd"
    },
    {
      value: "pink-layout",
      label: "Pink",
      code: "$theme-pink",
      color: "#d63384"
    },
    {
      value: "red-layout",
      label: "Red",
      code: "$theme-red",
      color: "#dc3545"
    },
    {
      value: "orange-layout",
      label: "Orange",
      code: "$theme-orange",
      color: "#fd7e14"
    },
    {
      value: "yellow-layout",
      label: "Yellow",
      code: "$theme-yellow",
      color: "#ffc107"
    },
    {
      value: "green-layout",
      label: "Green",
      code: "$theme-green",
      color: "#198754"
    },
    {
      value: "teal-layout",
      label: "Teal",
      code: "$theme-teal",
      color: "#20c997"
    },
    {
      value: "cyan-layout",
      label: "Cyan",
      code: "$theme-cyan",
      color: "#0dcaf0"
    }
  ],
  HELP_CENTNER_LINK: "https://help.getgps.online",
  MAX_FILE_SIZE_LIMIT: 2,
  MIN_MAINTENANCE_NAME: 3,
  MAX_MAINTENANCE_NAME: 50,
  SPEED_CONTROL: 1000,
  DATE_FORMAT: "date_format",
  TIME_FORMAT: "time_format",
  DATE_TIME_FORMAT: "date_time_format",
  SETTINGS: {
    date_format: "DD/MM/YYYY",
    date_time_format: "DD/MM/YYYY HH:mm A",
    time_format: "hh:mm A",
    timezone: "UTC"
  },
  REQUEST_TIME_OUT: 30000,
  MIN_GEOFENCE_NAME: 3,
  MAX_GEOFENCE_NAME: 30
};
