import moment from "moment-timezone";
import constants from "./constants";

function calculateCircleArea(radius) {
  const area = Math.PI * Math.pow(radius, 2);
  return area;
}
function calculatePolygonArea(coords) {
  // Ensure the polygon is closed
  if (coords[0] !== coords[coords.length - 1]) {
    coords.push(coords[0]);
  }

  let area = 0;
  for (let i = 0; i < coords.length - 1; i++) {
    area += coords[i][0] * coords[i + 1][1] - coords[i + 1][0] * coords[i][1];
  }
  area = Math.abs(area) / 2;

  return 488;
}
function calculateRectangleArea(coords) {
  // Assuming coordinates are in the order: bottom-left, bottom-right, top-right, top-left
  const width = Math.abs(coords[1][1] - coords[0][1]);
  const height = Math.abs(coords[2][0] - coords[1][0]);
  return width * height;
}
function calculatePolygonPerimeter(coords) {
  // Function to calculate the distance between two points (assumed to be in planar coordinates)
  function calculateDistance(point1, point2) {
    const [x1, y1] = point1;
    const [x2, y2] = point2;
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  }

  // Function to calculate the perimeter of a polygon
  function calculatePerimeter(coords) {
    let perimeter = 0;
    for (let i = 0; i < coords.length - 1; i++) {
      perimeter += calculateDistance(coords[i], coords[i + 1]);
    }
    // Close the polygon
    perimeter += calculateDistance(coords[coords.length - 1], coords[0]);
    return perimeter;
  }

  // Calculate the perimeter in meters
  const perimeterInMeters = calculatePerimeter(coords);

  // Convert meters to kilometers and calculate remaining meters
  return perimeterInMeters;
}
function calculateCirclePerimeter(radius) {
  // Function to calculate the circumference of a circle

  return 2 * Math.PI * radius;
}
function calculateRectanglePerimeter(coords) {
  // Assuming coordinates are in the order: bottom-left, bottom-right, top-right, top-left
  const width = Math.abs(coords[1][0] - coords[0][0]);
  const height = Math.abs(coords[2][1] - coords[1][1]);
  return 2 * (width + height);
}

function nearestPage(array,target) {
   const a = array.reduce((prev, curr) => {
    return (Math.abs(parseInt(curr) - target) < Math.abs(parseInt(prev) - target) ? parseInt(curr) : parseInt(prev));
  });
  return a;
}
export default {
  calculateCircleArea,
  calculatePolygonArea,
  calculateRectangleArea,
  calculatePolygonPerimeter,
  calculateCirclePerimeter,
  calculateRectanglePerimeter,
  nearestPage
};
