import ability from "./ability";

export const canNavigate = (to) =>
  to.matched.some((route) =>
    route.meta && route.meta.action && route.meta.resource
      ? ability.can(route.meta.action || "r", route.meta.resource)
      : true
  );

export const _ = undefined;
