export default {
  namespaced: true,
  state: {
    enable_generate: true
  },
  getters: {},
  mutations: {
    SET_ENABLE_GENERATE(state, val) {
      state.enable_generate = val;
    }
  },
  actions: {}
};
