import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import user from "./user";
import socket from "./socket";
import device from "./device";
import account from "./account";
import authorized from "./authorized";
import mapLoader from "./mapLoader";
import report from "./report";
import dashboard from "./dashboard";
import sensor from "./sensor";
import route from "./route";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    user,
    socket,
    appConfig,
    verticalMenu,
    device,
    account,
    authorized,
    mapLoader,
    report,
    dashboard,
    sensor,
    route
  },
  strict: process.env.DEV
});
