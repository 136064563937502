import APIService from "./api";
// colors

/** This class includes all services defined in account-service */
export default class AccountService extends APIService {
  service = "account-service";
  async getInvite(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getInviteData" },
      data,
      options
    );
  }

  async registerUserWithInvite(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "registerUserWithInvite" },
      data,
      options
    );
  }
  async joinUserWithInvite(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "joinUserWithInvite" },
      data,
      options
    );
  }
  async getUserAccounts(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getUserAccounts"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getUserSubAccounts(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getUserSubAccounts"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getUserDealerSubAccounts(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getUserDealerSubAccounts"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getUserConsumerSubAccounts(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getUserConsumerSubAccounts"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getAccountUsers(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getAccountUsers"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getChildAccounts(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getChildAccounts" },
      data,
      options
    );
  }
  async getUserRoles(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getUserRoles"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getSubAccountUserRoles(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getSubAccountUserRoles"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getSubDealerAccountUserRoles(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getSubDealerAccountUserRoles"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getSubConsumerAccountUserRoles(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getSubConsumerAccountUserRoles"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getUserRolesRead(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getUserRoles"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async inviteUser(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "inviteUser"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async inviteMultipleUser(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "inviteMultipleUser"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async inviteUserSubAccount(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "inviteUserSubAccount"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async inviteUserSubDealerAccount(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "inviteUserSubDealerAccount"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async inviteUserSubConsumerAccount(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "inviteUserSubConsumerAccount"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async inviteMultipleUserSubAccount(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "inviteMultipleUserSubAccount"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async inviteMultipleUserSubDealerAccount(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "inviteMultipleUserSubDealerAccount"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async inviteMultipleUserSubConsumerAccount(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "inviteMultipleUserSubConsumerAccount"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getInvitationList(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getInvitationList"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getSubAccountInvitationList(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getSubAccountInvitationList"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getSubDealerAccountInvitationList(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getSubDealerAccountInvitationList"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getSubConsumerAccountInvitationList(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getSubConsumerAccountInvitationList"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async userRemoved(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "userRemoved"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async userSubAccountUserRemoved(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "userSubAccountUserRemoved"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async userSubDealerAccountUserRemoved(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "userSubDealerAccountUserRemoved"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async userSubConsumerAccountUserRemoved(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "userSubConsumerAccountUserRemoved"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async selectAccount(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "selectAccount"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getRoleData(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getOneRole"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async addRole(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "addRole"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async updateRole(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "updateRole"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async removeRole(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "deleteRole"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getAllPermissions(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getAllPermissions"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getRefData(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getRefData"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getDealerConsumerList(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getDealerConsumerList"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getDealerList(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getDealerList"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getConsumerList(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getConsumerList"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getSubDealerConsumerList(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getSubDealerConsumerList"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getSubDealerList(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getSubDealerList"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getSubConsumerList(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getSubConsumerList"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async updateAccountStatus(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "updateAccountStatus"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async updateDealerAccountStatus(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "updateDealerAccountStatus"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async updateConsumerAccountStatus(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "updateConsumerAccountStatus"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async updateSubAccountStatus(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "updateSubAccountStatus"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async updateDealerSubAccountStatus(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "updateDealerSubAccountStatus"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async updateConsumerSubAccountStatus(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "updateConsumerSubAccountStatus"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async createDealerConsumerAccounts(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "createDealerConsumerAccounts"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async createDealerConsumerSubAccounts(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "createDealerConsumerSubAccounts"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async createDealerSubAccounts(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "createDealerSubAccounts"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async createConsumerSubAccounts(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "createConsumerSubAccounts"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async updateUserRole(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "updateUserRole"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async updateSubAccountUserRole(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "updateSubAccountUserRole"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async updateSubDealerAccountUserRole(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "updateSubDealerAccountUserRole"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async updateSubConsumerAccountUserRole(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "updateSubConsumerAccountUserRole"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async checkUserNameAvailability(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "checkUserNameAvailability"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getTeamPeoples(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getTeamPeoples" },
      data,
      options
    );
  }
  async getAccountDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAccountDetails" },
      data,
      options
    );
  }
  async getDealerAccountDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getDealerAccountDetails" },
      data,
      options
    );
  }
  async getConsumerAccountDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getConsumerAccountDetails" },
      data,
      options
    );
  }
  async updateAccountDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "updateAccountDetails"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async updateDealerAccountDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "updateDealerAccountDetails"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async updateConsumerAccountDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "updateConsumerAccountDetails"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async updateChildAccountDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "updateChildAccountDetails"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async updateDealerChildAccountDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "updateDealerChildAccountDetails"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async updateConsumerChildAccountDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "updateConsumerChildAccountDetails"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async dashboardDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "dashboardDetails"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getUsersList(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "account-service",
        method: "getUsersList"
      },
      data,
      { ...options, show_loader: false }
    );
  }
}
