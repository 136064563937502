import FileService from "@/libs/api/file-service";
import store from "@/store";
export default async function loadAppSettings(i18n, lang) {
  try {
    await Promise.all([
      new Promise((resolve, reject) => {
        new FileService()
          .getLanguages()
          .then((res) => {
            if (res && res.length) {
              let resp = res.map((l) => {
                //   l.img = require(`${l.img}`);
                return l;
              });
              resp = JSON.stringify(resp);
              localStorage.setItem("LANGUAGES", resp);
              getSupportedRtlLang();
              resolve();
            }
          })
          .catch((err) => {
            resolve();
          });
      }),
      new Promise((resolve, reject) => {
        new FileService()
          .getTranslation({ lang: lang.toUpperCase() })
          .then((res) => {
            i18n.setLocaleMessage(lang, res);
            resolve();
          })
          .catch((err) => {
            resolve();
          });
      })
    ]);
    // console.log("App Settings Loaded");
  } catch (err) {
    console.log(err);
  }
}

function getSupportedRtlLang() {
  const supportedRtlLang =
    localStorage.getItem("LANGUAGES") &&
    JSON.parse(localStorage.getItem("LANGUAGES")).length
      ? JSON.parse(localStorage.getItem("LANGUAGES"))
          .filter((e) => e.rtl === true)
          .map((e) => e.locale)
      : [];
  setTimeout(() => {
    store.commit(
      "appConfig/TOGGLE_RTL",
      supportedRtlLang.includes(localStorage.getItem("lang"))
    );
  }, 100);

  return;
}
