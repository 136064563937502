import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyCY9nb6BsJi2sQ6Bb2dj8Nf_oi9x44kVt0",
  authDomain: "polygonenovate.firebaseapp.com",
  projectId: "polygonenovate",
  storageBucket: "polygonenovate.appspot.com",
  messagingSenderId: 296933935212,
  appId: "1:296933935212:web:d9a62116dc820742857049",
  measurementId: "G-V796PD9BEP"
};

const app = initializeApp(firebaseConfig);

export default app;
