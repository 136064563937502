import APIService from "./api";

/** This class includes all services defined in file-service */
export default class FileService extends APIService {
  service = "file-gate-service";
  async uploadFile(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.fileApi(data);
  }
  async getTranslation(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const url = `/fe/translations/${data.lang}`;
    return await this.fileMessages(data, url);
  }
  async getLanguages(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const url = `/poly/fe/supported/languages`;
    return await this.fileMessages(data, url);
  }
}
