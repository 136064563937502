import state from "./state";

export function getProtocolList() {
  return state.parameters;
}
export function getIcons() {
  return state.icons;
}

export function getStreamUrlData(state) {
  return state.streamUrlData;
}
