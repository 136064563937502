import Vue from "vue";
import VueI18n from "vue-i18n";
import ar from "vee-validate/dist/locale/ar.json";
import en from "vee-validate/dist/locale/en.json";
import ru from "vee-validate/dist/locale/ru.json";

Vue.use(VueI18n);
let lang = "en";
function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = { en: {} };
  // locales.keys().forEach((key) => {
  //   const matched = key.match(/([A-Za-z0-9-_]+)\./i);
  //   if (matched && matched.length > 1) {
  //     const locale = matched[1];
  //     messages[locale] = locales(key);
  //     messages[locale].validation = getVeeValidateLocale(locale).messages;
  //   }
  // });
  return messages;
}
function getVeeValidateLocale(locale) {
  const veeValidateLocales = {
    en: en,
    ar: ar,
    ru: ru,
    default: en
  };

  const result = veeValidateLocales[locale] || veeValidateLocales["default"];
  return result;
}

function getLanguage() {
  if (localStorage.getItem("lang")) {
    return localStorage.getItem("lang");
  } else {
    localStorage.setItem("lang", "en");
    return "en";
  }
  // for future reference
  // try {
  //   if (window.location.href.includes("new-user-onboarding")) {
  //     let whiteLabeling = localStorage.getItem("USER_ACCOUNT_WHITE_LABELING");
  //     whiteLabeling = whiteLabeling ? JSON.parse(whiteLabeling) : null;

  //     if (whiteLabeling.lang) {
  //       lang = whiteLabeling.lang;
  //     }
  //   } else {
  //     const account =
  //       localStorage.getItem("USER_ACCOUNT_DETAILS") &&
  //       JSON.parse(localStorage.getItem("USER_ACCOUNT_DETAILS"));
  //     if (
  //       account &&
  //       account.white_labeling &&
  //       account.white_labeling &&
  //       account.white_labeling.lang
  //     ) {
  //       lang = account.white_labeling.lang;
  //     }
  //   }
  //   localStorage.setItem("lang", lang);
  //   return lang;
  // } catch (err) {
  //   localStorage.setItem("lang", "en");
  //   return "en";
  // }
}
export default new VueI18n({
  locale: getLanguage(),
  fallbackLocale: getLanguage(),
  messages: loadLocaleMessages()
});
