import { $themeConfig } from "@themeConfig";

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed:
      localStorage.getItem("VERTICAL_SIDE_MENU_COLLAPSED") == 2 ? true : false,
    isVerticalMenuMouseHover: false
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val;

      localStorage.setItem("VERTICAL_SIDE_MENU_COLLAPSED", val ? 2 : 1);
    },
    UPDATE_VERTICAL_MENU_MOUSE_HOVERED(state, val) {
      state.isVerticalMenuMouseHover = val;
    }
  },
  actions: {}
};
