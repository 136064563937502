import APIService from "./api";
import useJwt from "@/auth/jwt/useJwt";
import { isUserLoggedIn } from "@/auth/utils";

/** This class will include all services defined in auth-service */
export default class AuthService extends APIService {
  service = "auth-service";
  async login(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "signin" },
      data,
      options
    );
  }
  async changePassword(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "changePassword" },
      data,
      options
    );
  }

  async getAccountDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: "auth-service",
        method: "getAccountDetails"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getUserProfile(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getUserProfile" },
      data,
      options
    );
  }
  async updateUserProfile(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "updateUserProfile" },
      data,
      options
    );
  }
  async updateProfilePic(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "uploadProfilePic" },
      data,
      options
    );
  }

  async logout(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const reqData = data;
    if (localStorage.getItem("SESSION_LOGIN")) {
      reqData.session_login = localStorage.getItem("SESSION_LOGIN");
    }
    // Remove userData from localStorage
    // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
    if (localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)) {
      await this.api(
        { service: this.service, method: "signout" },
        data,
        options
      );
    }
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
    localStorage.removeItem("USER_ACCOUNT_ID");
    localStorage.removeItem("USER_SUB_ACCOUNTS_RESTRICTED");
    localStorage.removeItem("USER_ACCOUNT_TYPE");
    localStorage.removeItem("USER_ACCOUNT_ROLE_NAME");
    localStorage.removeItem("USER_ACCOUNT_ROLE_ID");
    localStorage.removeItem("USER_PROFILE_DETAILS");
    localStorage.removeItem("USER_ACCOUNT_NAME");
    localStorage.removeItem("SESSION_LOGIN");
    localStorage.removeItem("BEARER_TOKEN");
    // Remove userData from localStorage
    localStorage.removeItem("userData");
    localStorage.removeItem("IS_SERVER_ERROR");
    localStorage.removeItem("USER_ACCOUNT_DETAILS");
    // acl
    localStorage.removeItem("USER_PERMISSIONS");
    // localStorage.removeItem("sessionId");
    localStorage.removeItem("notificationToken");
    //localStorage.removeItem("lang");
    // window.location.reload();
    return;
  }

  async forgotPasswordRequest(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "resetPasswordRequest" },
      data,
      options
    );
  }

  async resetPassword(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "resetPassword" },
      data,
      options
    );
  }

  async verifyEmailRequest(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "verifyEmailRequest" },
      data,
      options
    );
  }
  async verifyEmail(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "verifyEmail" },
      data,
      options
    );
  }

  async verifyMobileRequest(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "verifyMobileRequest" },
      data,
      options
    );
  }

  async verifyMobile(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "verifyMobile" },
      data,
      options
    );
  }
  async getUserInvitations(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getUserInvitations" },
      data,
      options
    );
  }
  async getActiveSessions(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getActiveSessions" },
      data,
      options
    );
  }
  async logoutSessions(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "logoutSessions" },
      data,
      options
    );
  }
  async getConfigData(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getConfigData" },
      data,
      options
    );
  }
}
